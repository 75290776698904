import { useSearchParams } from "@remix-run/react";
import { useMutation } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { UtmParamUtil } from "@wind/marketing/util";

import PublicLeadsApi from "~/marketing/leads/PublicLeadsApi.js";

interface Props {
  className?: string;
}

const WaitlistForm = ({ className }: Props) => {
  const { register, handleSubmit } = useForm<{ email: string }>();
  const [searchParams] = useSearchParams();

  const utmParams = UtmParamUtil.parseUtmParamsFromSearchParams(searchParams);

  const joinWaitlistMutation = useMutation({
    mutationFn: async (email: string) => {
      await new PublicLeadsApi().createLead({ email, ...utmParams });
    },
    onError: () => {
      toast.error("Something went wrong, please try again.");
    },
  });

  const onSubmit: SubmitHandler<{ email: string }> = (data) => {
    joinWaitlistMutation.mutate(data.email);
  };

  return (
    <div className={className}>
      <AnimatePresence>
        {joinWaitlistMutation.isSuccess && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="text-center text-md text-primary"
          >
            Thank you for joining the waitlist! We'll be in touch soon.
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {!joinWaitlistMutation.isSuccess && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-row gap-2 rounded-full p-2 bg-gradient-to-b from-white/90 to-white/50 border-white focus-within:ring-highlight focus-within:ring-2 focus-within:ring-offset-2 w-full max-w-lg ring-2 ring-highlight">
                <input
                  {...register("email", { required: true })}
                  type="email"
                  placeholder="Work Email"
                  autoComplete="work email"
                  className="border-none bg-transparent focus:outline-none focus:ring-accent focus:ring-0 rounded-full pl-4 w-full"
                />
                <button
                  type="submit"
                  className="bg-highlight bg-gradient-to-b from-highlight/80 to-highlight hover:bg-highlight text-accent-inverse border border-highlight px-6 py-2 rounded-full text-lg font-medium transition-all flex-none whitespace-nowrap"
                >
                  Join Waitlist
                </button>
              </div>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default WaitlistForm;
